/**
 * RTK Login Component
 *
 * A form component that handles user authentication with improved error handling.
 *
 * Features:
 * - Email and password validation using Zod schema
 * - Graceful error handling for API responses
 * - Clear user feedback for authentication errors
 * - Loading states during submission
 * - Navigation guards for protected routes
 * - Password recovery and registration links
 * - Single error message display for invalid credentials
 *
 * Error Handling:
 * - Displays server validation errors in a user-friendly manner
 * - Handles 422 status code for invalid credentials
 * - Prevents technical error messages from reaching the user interface
 *
 * @component
 */

import { useAuth } from '@/lib/hooks/use-auth';
import { useMounted } from '@/lib/hooks/use-mounted';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { FC, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { TextFieldElement } from '@/components/_shared/form-elements';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

// Validation schema for the login form
const LoginSchema = z.object({
  email: z.string().min(1, 'Email is required').email('Must be a valid email').max(255, 'Email must be less than 255 characters'),
  password: z.string().min(1, 'Password is required').max(255, 'Password must be less than 255 characters')
});
type LoginSchemaType = z.infer<typeof LoginSchema>;

// Interface for API error response
interface ApiErrorResponse {
  status: string;
  message: string;
  errors?: {
    email?: string[];
    password?: string[];
  };
}
export const RtkLogin: FC<React.PropsWithChildren<unknown>> = props => {
  const isMounted = useMounted();
  const router = useRouter();
  const {
    login
  } = useAuth();
  const {
    disableGuard
  } = router.query;
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [generalError, setGeneralError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const {
    control,
    setError,
    getValues
  } = useForm<LoginSchemaType>({
    resolver: zodResolver(LoginSchema),
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: ''
    }
  });
  /**
   * Handles the form submission and authentication process
   * @param values - Form values containing email and password
   */
  const onSubmit: SubmitHandler<LoginSchemaType> = async values => {
    setGeneralError(null);
    setIsSubmitting(true);
    try {
      const loginResult = await login({
        email: values.email,
        password: values.password,
        redirectPath: '/crm/communication-crm'
      });
      if (loginResult?.error) {
        setIsSubmitting(false);
        // Handle 422 validation errors
        if (loginResult.error.status === 422) {
          const apiError = loginResult.error.data;

          // Handle field-specific errors
          if (apiError.errors) {
            Object.entries(apiError.errors).forEach(([field, messages]) => {
              if (Array.isArray(messages) && messages.length > 0) {
                setError(field as keyof LoginSchemaType, {
                  type: 'server',
                  message: messages[0]
                });
              }
            });
          }

          // Set general error message if no field-specific errors
          if (!apiError.errors || Object.keys(apiError.errors).length === 0) {
            setGeneralError(apiError.message);
          }
        } else {
          // Handle other error statuses
          setGeneralError(loginResult.error.data.message || 'An error occurred during login');
        }
        return;
      }
    } catch (err) {
      setIsSubmitting(false);
      console.error('Login error:', err);
      if (isMounted()) {
        setGeneralError('An unexpected error occurred. Please try again.');
      }
    }
  };

  /**
   * Handles the form submission event
   * @param e - Form submission event
   */
  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await onSubmit(getValues());
    } catch (error) {
      console.error('Error in form submission:', error);
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return <form noValidate onSubmit={handleFormSubmit} className="space-y-6" {...props} data-sentry-component="RtkLogin" data-sentry-source-file="rtk-login.tsx">
      <TextFieldElement name="email" label="Email Address" type="email" autoComplete="username" control={control} InputLabelProps={{
      shrink: true
    }} fullWidth data-sentry-element="TextFieldElement" data-sentry-source-file="rtk-login.tsx" />
      <TextFieldElement name="password" label="Password" type={showPassword ? 'text' : 'password'} autoComplete="current-password" control={control} InputLabelProps={{
      shrink: true
    }} fullWidth InputProps={{
      endAdornment: <InputAdornment position="end">
              <IconButton aria-label="toggle password visibility" onClick={togglePasswordVisibility} edge="end" size="large">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
    }} data-sentry-element="TextFieldElement" data-sentry-source-file="rtk-login.tsx" />

      {/* Display authentication error in a single location */}
      {generalError && <div className="mt-2 text-sm text-center text-red-500">
          {generalError}
        </div>}

      <div className="flex items-center justify-between">
        <NextLink href={`/authentication/password-recovery`} passHref data-sentry-element="NextLink" data-sentry-source-file="rtk-login.tsx">
          <span className="text-sm font-medium text-[#ff792a] hover:text-[#e66a1f]">
            Forgot your password?
          </span>
        </NextLink>

        <NextLink href={`/get-started`} passHref data-sentry-element="NextLink" data-sentry-source-file="rtk-login.tsx">
          <span className="text-sm font-medium text-[#ff792a] hover:text-[#e66a1f]">
            Create Account
          </span>
        </NextLink>
      </div>

      <button type="submit" disabled={isSubmitting} className="w-full rounded-md border border-transparent bg-[#ff792a] p-4 text-sm font-medium text-white shadow-sm hover:bg-[#e66a1f] focus:outline-none focus:ring-2 focus:ring-[#ff792a] focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed">
        {isSubmitting ? 'Signing In...' : 'Sign In'}
      </button>
    </form>;
};