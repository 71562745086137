import React from 'react';
import NextLink from 'next/link';
const CopyrightTerms = () => {
  return <div className="flex justify-center mt-6 text-gray-400" data-sentry-component="CopyrightTerms" data-sentry-source-file="copyright-terms.tsx">
      &copy; RipeMetrics {new Date().getFullYear()}. All rights reserved.
      <NextLink href="https://ripemetrics.com/legal/privacy-policy" passHref className="px-1 font-medium text-zinc-600 hover:text-orange-500 hover:underline" target="_blank" data-sentry-element="NextLink" data-sentry-source-file="copyright-terms.tsx">
        Privacy
      </NextLink>
      and
      <NextLink href="/legal/terms-of-service" passHref className="px-1 font-medium text-zinc-600 hover:text-orange-500 hover:underline" target="_blank" data-sentry-element="NextLink" data-sentry-source-file="copyright-terms.tsx">
        Terms
      </NextLink>
      .
    </div>;
};
export default CopyrightTerms;