import type { FC, ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useAuth } from '@/lib/hooks/use-auth';
import { SplashScreen } from '@/components/_shared/splash-screen';

/**
 * Props interface for the GuestGuard component
 * @interface GuestGuardProps
 * @property {ReactNode} children - The child components to be rendered if the guest access is allowed
 */
interface GuestGuardProps {
  children: ReactNode;
}

/**
 * GuestGuard Component
 *
 * This component acts as a protection layer for guest-only routes (like login, register, etc.).
 * It prevents authenticated users from accessing these routes by redirecting them to the dashboard.
 *
 * Usage:
 * ```tsx
 * <GuestGuard>
 *   <LoginPage />
 * </GuestGuard>
 * ```
 *
 * Flow:
 * 1. Component mounts and waits for router to be ready
 * 2. Checks if user is authenticated
 * 3. If authenticated, redirects to dashboard
 * 4. If not authenticated, renders the children
 *
 * @param {GuestGuardProps} props - Component props
 * @returns {ReactNode | null} Returns null during checks, children if access is allowed
 */
export const GuestGuard: FC<React.PropsWithChildren<GuestGuardProps>> = props => {
  const {
    children
  } = props;
  const {
    isAuthenticated,
    isInitialized
  } = useAuth();
  const router = useRouter();
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    if (!router.isReady || !isInitialized) {
      return;
    }
    if (isAuthenticated) {
      // Use window.location for a hard redirect to ensure clean state
      window.location.href = '/crm/communication-crm';
      return;
    }
    setChecked(true);
  }, [router.isReady, isAuthenticated, isInitialized]);

  // Show the splash screen while checking auth or during redirect
  if (!checked || isAuthenticated) {
    return <SplashScreen />;
  }

  // Only render children if user is not authenticated
  return <>{children}</>;
};
GuestGuard.propTypes = {
  children: PropTypes.node
};
export default GuestGuard;